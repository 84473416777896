// import '../molecules/reaction1_1.mol'

export const BASEURL = '/molToJson/'

// 配置文件名
export const molUrlArr = [
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "Sisunatovir",
  "Disoxaril",
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "Sisunatovir",
  "Disoxaril",
  "Sisunatovir",
  "Disoxaril",
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "Sisunatovir",
  "Disoxaril",
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "Sisunatovir",
  "Disoxaril",
  "VicrivirocMaleate",
  "Pleconaril",
  "Presatovir",
  "Desaspidin",
  "Sisunatovir",
  "Disoxaril",
]

