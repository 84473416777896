const useFormatMol = () => {
  const upComparison = {
    "Molecular Weight": "分子量",
    // "XLogP3-AA": "XLogP3-AA",
    // "Hydrogen Bond Donor Count": "氢键供体计数",
    // "Hydrogen Bond Acceptor Count": "氢键受体计数",
    // "Rotatable Bond Count": "可旋转债券计数",
    "Exact Mass": "精确质量",
    "Monoisotopic Mass": "单一同位素质量",
    "Topological Polar Surface Area": "拓扑极表面积",
    // "Heavy Atom Count": "重原子计数",
    "Formal Charge": "形式电荷",
    Complexity: "复杂性",
    // "Isotope Atom Count": "同位素原子计数",
    // "Defined Atom Stereocenter Count": "定义的原子立体中心计数",
    // "Undefined Atom Stereocenter Count": "未定义原子立体中心计数",
    // "Defined Bond Stereocenter Count": "确定的键立体中心计数",
    // "Undefined Bond Stereocenter Count": "未定义键立体中心计数",
    // "Covalently-Bonded Unit Count": "共价键合单位计数",
    // "Compound Is Canonicalized": "Compound 被规范化",
  }
  const downComparison = {
    // "Molecular Weight": "分子量",
    // "XLogP3-AA": "XLogP3-AA",
    "Hydrogen Bond Donor Count": "氢键供体计数",
    "Hydrogen Bond Acceptor Count": "氢键受体计数",
    "Rotatable Bond Count": "可旋转债券计数",
    // "Exact Mass": "精确质量",
    // "Monoisotopic Mass": "单一同位素质量",
    // "Topological Polar Surface Area": "拓扑极表面积",
    "Heavy Atom Count": "重原子计数",
    // "Formal Charge": "形式电荷",
    // Complexity: "复杂性",
    "Isotope Atom Count": "同位素原子计数",
    "Defined Atom Stereocenter Count": "定义的原子立体中心计数",
    "Undefined Atom Stereocenter Count": "未定义原子立体中心计数",
    "Defined Bond Stereocenter Count": "确定的键立体中心计数",
    "Undefined Bond Stereocenter Count": "未定义键立体中心计数",
    "Covalently-Bonded Unit Count": "共价键合单位计数",
    // "Compound Is Canonicalized": "Compound 被规范化",
  }

  const nameComparison = {
    name: "名称",
    formula: "分子式",
  }

  const resData = {}
  const getMolJson = (res) => {
    resData.name = JSON.parse(res).Record.RecordTitle
    deepTraverse(JSON.parse(res))
    return resData
  }

  const deepTraverse = (obj) => {
    // console.log(resData)
    if (Array.isArray(obj)) {
      // 如果是数组，遍历数组中的每一项
      obj.forEach((item) => {
        if (item.TOCHeading && item.TOCHeading === "Computed Properties") {
          resData.properties = item.Section
        } else if (item.TOCHeading && item.TOCHeading === "Molecular Formula") {
          resData.formula = item.Information[0].Value.StringWithMarkup[0].String
        } else {
          deepTraverse(item)
        }
      })
    } else if (typeof obj === "object" && obj !== null) {
      // 如果是对象，遍历对象的每个键值对
      for (const key in obj) {
        // console.log(obj[key].TOCHeading)
        if (obj.hasOwnProperty(key)) {
          deepTraverse(obj[key])
        }
      }
    }
  }

  return {
    upComparison,
    downComparison, //中英对照
    nameComparison,
    getMolJson,
  }
}

export default useFormatMol
