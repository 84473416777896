export default function Footer() {
  return (
    <div
      style={{
        height: "100px",
        color: "#ffffff50",
        margin: "0 50px 0 50px",
        fontSize: "12px",
      }}>
      <div
        style={{
          borderBottom: "1px solid #ffffff50",
        }}></div>
      <div
        style={{
          marginTop: "30px",
        }}>
        ©2024 - 上海超算造物材料科技有限公司 版权所有
        <span> | </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => window.open("https://beian.miit.gov.cn/")}>
          沪ICP备2024094192号-1
        </span>
        <span> | </span>
        <span>邮箱：abiii@chemputer.cn</span>
      </div>
    </div>
  )
}
