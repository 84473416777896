
import CarouselArco from "./component/CarouselView/CarouselArco"

let screenHeight = window.innerHeight
const HEIGHT = screenHeight - screenHeight * 0.4
export default function Index() {
  return (
    <div
      style={{
        margin: "30px 0 30px 0",
        display: "flex",
        justifyContent: "center",
      }}>
      <div
        style={{
          zIndex: 10000,
          position: "absolute",
          width: "100%",
          height: HEIGHT,
        }}></div>
      <CarouselArco></CarouselArco>
    </div>
  )
}
