import Head from "./component/Head/head"
import Footer from "./component/Footer/footer"
import Index from "./pages/Index/index"

function App() {
  return (
    <div>
      <Head></Head>
      <Index></Index>
      <Footer></Footer>
    </div>
  )
}

export default App
